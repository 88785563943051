import { Input } from 'components/Input';
import styled from 'styled-components';
import { Label } from 'styles/components';

export const Popup = styled.div`
    width: 100vw;
    max-width: 600px;
    padding: 56px 64px;
    box-sizing: border-box;
`;

export const Title = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;
    line-height: 1;
    text-align: center;
    margin-bottom: 64px;
`;

export const StyledLabel = styled(Label)`
    margin-bottom: 8px;
`;

export const StyledInput = styled(Input)`
    margin-bottom: 32px;
`;

export const Error = styled.div`
    font-size: ${({ theme }) => theme.fontSize.m}px;
    text-align: left;
    color: ${({ theme }) => theme.colors.error};
    margin-top: 8px;
`;
