import React from 'react';

interface SelectContextValue {
    currentValue: string;
    handlerSelect?: (value: string) => void;
}

export const SelectContext = React.createContext<SelectContextValue>({
    currentValue: '',
    handlerSelect: undefined,
});
