import { Button } from 'components/Button';
import styled from 'styled-components';

export const Logout = styled.div`
    width: 100vw;
    max-width: 290px;
    box-sizing: border-box;
    padding: 24px;
`;

export const Title = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-weight: 700;
    line-height: 1;
    text-align: center;
    margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;
