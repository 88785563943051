import { Container } from 'components/Container';
import React, { memo } from 'react';
import { Icon } from 'components/Icon';
import { useMatchMedia } from 'utils/hooks';
import { laptopUp } from 'config';
import * as S from './styles';

export const Footer = memo(() => {
    const laptopMatch = useMatchMedia(laptopUp);

    return (
        <S.Footer>
            <Container>
                <S.TelegramLink href="https://web.telegram.org/k/#@PlaySkills" target="_blank">
                    <Icon glyph="telegram" size={laptopMatch ? 48 : 32} />
                </S.TelegramLink>
                <S.Copyright>2024 PlaySkills by Gamification Lab</S.Copyright>
            </Container>
        </S.Footer>
    );
});
