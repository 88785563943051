import { Input, InputColors, InputSizes, InputVariants } from 'components/Input';
import { API_URL } from 'config';
import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { validateEmail } from 'utils/helpers';
import * as S from './styles';

const notifySender = async (email: string) => {
    const response = await fetch(`${API_URL}/notify/access-waiting`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });

    if (response.status === 400) {
        throw new Error('Error sending email');
    }

    return response.json();
};

const leadSender = async (email: string) => {
    const data = new FormData();
    data.append('Email', email);
    data.append('honeypot', '');

    const response = await fetch(
        'https://script.google.com/macros/s/AKfycbyuhHBy6Fwlk8JEQqybMuxGCSjnytQae08-nuUdQ2W7ZkGB9L0joXdHVh77RDSpqaKfeg/exec',
        {
            method: 'post',
            body: data,
        }
    );

    return response.json();
};

export const LeadForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isServerError, setIsServerError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const theme = useTheme();

    const handlerChange = useCallback((value: string) => {
        setIsSuccess(false);
        setIsError(false);
        setIsServerError(false);
        setEmail(value);
    }, []);

    const sendForm = useCallback(async () => {
        if (email.length === 0 || validateEmail(email)) {
            setIsError(true);
            return;
        }

        try {
            setIsLoading(true);
            const leadResult = await leadSender(email);

            if (leadResult.result === 'success') {
                const notifyResult = await notifySender(email);

                if (notifyResult.email === email) {
                    setIsLoading(false);
                    setIsSuccess(true);
                    setEmail('');
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setIsServerError(true);
        }
    }, [email]);

    const handlerRightIconClick = useCallback(() => {
        sendForm();
    }, [sendForm]);

    return (
        <>
            <Input
                type="email"
                placeholder="Введите почту"
                value={email}
                variant={InputVariants.outline}
                color={isError ? InputColors.error : InputColors.lightGray}
                inputSize={InputSizes.l}
                onRightIconClick={handlerRightIconClick}
                onChange={handlerChange}
                disabled={isLoading || isSuccess}
                iconRight={isLoading ? 'loader' : isSuccess ? 'success' : 'enter'}
                notEmptyIconColor={!(isLoading || isSuccess) ? theme.colors.darkGray : undefined}
                emptyIconColor={!(isLoading || isSuccess) ? theme.colors.darkGray : undefined}
                isIconRightAnimation={isLoading}
                opacityIcons={!(isLoading || isSuccess)}
                required
            />
            {isSuccess && (
                <S.Success>
                    Успешно! Мы добавили вашу почту в нашу рассылку. Мы будем делиться с вами новостями и материалами, а
                    также сообщим о появлении новых модулей :)
                </S.Success>
            )}
            {isError && <S.Error>Введите корректный email</S.Error>}
            {isServerError && <S.Error>Ошибка отправки email</S.Error>}
            <S.Descr>
                Я соглашаюсь на{' '}
                <a href="https://www.playskills.io/persdata" target="_blank" rel="noreferrer">
                    обработку персональных данных
                </a>{' '}
                и{' '}
                <a href="https://www.playskills.io/termsofuse" target="_blank" rel="noreferrer">
                    политикой конфиденциальности и условиями использования
                </a>
            </S.Descr>
        </>
    );
};
