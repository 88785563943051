import { createSlice } from '@reduxjs/toolkit';
import { checkAuth } from '../services/checkAuth';
import { login } from '../services/login';
import { logout } from '../services/logout';
import { AuthSchema } from '../types/AuthSchema';

const initialState: AuthSchema = {
    loading: false,
    checkAuthLoading: true,
    error: undefined,
    isAuth: false,
    user: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            state.isAuth = true;
            state.user = action.payload.user;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(checkAuth.pending, (state) => {
            state.checkAuthLoading = true;
        });
        builder.addCase(checkAuth.fulfilled, (state, action) => {
            state.checkAuthLoading = false;
            state.isAuth = true;
            state.user = action.payload.user;
        });
        builder.addCase(checkAuth.rejected, (state) => {
            state.checkAuthLoading = false;
        });
        builder.addCase(logout.pending, (state) => {
            state.loading = false;
            state.error = undefined;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.loading = false;
            state.isAuth = false;
            state.user = undefined;
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { actions: authActions } = authSlice;
export const { reducer: authReducer } = authSlice;
