import React from 'react';
import * as C from 'styles/components';
import * as S from './styles';

export const MobileDescription = () => (
    <S.MobileDescription>
        <C.Text>
            <p>
                Лаборатория геймификации Сбера приглашает вас отправиться в магический мир цивилизации Древнего Египта.
            </p>
            <p>Материалы рассчитаны на учеников 5 класса, однако они будут полезны и для более широкой аудитории.</p>
        </C.Text>
    </S.MobileDescription>
);
