import { Login } from 'components/Login';
import { UserName } from 'components/UserName';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth, getIsCheckAuthLoading } from 'store/auth';
import * as S from './styles';

interface UserProps {
    variant: 'dark' | 'light';
}

export const User = memo((props: UserProps) => {
    const { variant } = props;
    const isAuth = useSelector(getIsAuth);
    const isCheckAuthLoading = useSelector(getIsCheckAuthLoading);
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        setToken(localStorage.getItem('token'));
    }, [isCheckAuthLoading]);

    if (token && isCheckAuthLoading) return null;

    return <S.User>{isAuth ? <UserName variant={variant} /> : <Login />}</S.User>;
});
