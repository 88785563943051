import { prepareModulesData, prepareThemesData } from 'utils/helpers';
import Fuse from 'fuse.js';
import ModuleImg1 from 'assets/images/module-1.webp';
import ModuleImg2 from 'assets/images/module-2.webp';
import ModuleImg3 from 'assets/images/module-3.webp';
import ModuleImg4 from 'assets/images/module-4.webp';
import ModuleImg5 from 'assets/images/module-5.webp';

export interface Theme {
    id?: string;
    roles?: string[];
    number: number;
    name: string;
    duration?: number;
    descr: string;
    result?: string;
    groups?: string[];
    tags?: string[];
    types?: string[];
    route: string;
}

export interface Module {
    id?: string;
    number: number;
    name: string;
    image: string;
    descr: string;
    groups?: string[];
    locked?: boolean;
    roles?: string[];
    themes: Omit<Theme, 'module'>[];
}

export const data: Module[] = [
    {
        number: 1,
        name: 'В Африке реки вот такой ширины',
        image: ModuleImg1,
        descr: `<p>
        В этом модуле ты узнаешь, какую роль река Нил играла в жизни древних египтян, почему они знали только три
        времени года и чем занимались в это время, как египтяне узнавали о месторождениях полезных ископаемых,
        почему они не охотились на крокодилов и как освящали свои жилища.
      </p>`,
        themes: [
            {
                number: 1,
                name: 'Долина Нила, ландшафт',
                duration: 3,
                descr: `<ul>
            <li>Когда в долине Нила появились первые люди</li>
            <li>Что такое дельта в письменности и на карте</li>
          </ul>`,
                result: `<ul>
            <li>Определять на карте районы, подходящие для основания поселений</li>
            <li>Построение дельты на карте</li>
          </ul>`,
                groups: ['Условия жизни'],
                types: ['Интерактив'],
                tags: ['природа', 'флора', 'фауна'],
                route: '/nature/intro/start',
            },
            {
                number: 2,
                name: 'Опасные насекомые',
                duration: 4,
                descr: '<p>Чем угрожали насекомые древним египтянам</p>',
                result: '<p>Управлять осадками для создания благоприятных жизненных условий</p>',
                groups: ['Условия жизни'],
                types: ['Интерактив'],
                tags: ['природа', 'флора', 'фауна'],
                route: '/nature/insects/intro',
            },
            {
                number: 3,
                name: 'Природные богатства',
                duration: 5,
                descr: `<ul>
            <li>Какими природными ископаемыми богат Египет</li>
            <li>Каким образом в древности их искали и как использовали</li>
            <li>Какие ритуалы проводили, чтобы привлечь удачу</li>
            <li>Как расположены слои полезных ископаемых</li>
          </ul>`,
                result: '<p>Определять на карте по условным обозначениям месторождения полезных ископаемых</p>',
                groups: ['Условия жизни'],
                types: ['Комикс'],
                tags: ['ритуалы', 'полезные ископаемые'],
                route: '/nature/resources/intro',
            },
            {
                number: 4,
                name: 'Растения',
                duration: 4,
                descr: `<ul>
            <li>Какие растения выращивали древние египтяне</li>
            <li>Как они использовали папирус</li>
          </ul>`,
                result: '<p>Изготовлять из папируса материал для письма</p>',
                groups: ['Земледелие'],
                types: ['Игра'],
                tags: ['папирус', 'письменность'],
                route: '/nature/agriculture/intro',
            },
            {
                number: 5,
                name: 'Разливы Нила',
                duration: 3,
                descr: '<p>Как проводился праздник Опет</p>',
                result: '<p>Руководить праздничной процессией</p>',
                groups: ['Земледелие'],
                types: ['Интерактив'],
                tags: ['ритуалы'],
                route: '/nature/nile-floods/intro',
            },
            {
                number: 6,
                name: 'Египетский календарь',
                duration: 3,
                descr: `<ul>
            <li>На сколько сезонов делился год в Древнем Египте</li>
            <li>Как назывались разные сезоны</li>
            <li>Чем занимались египтяне в каждый из сезонов</li>
          </ul>`,
                result: '<p>Определять время года по занятиям населения</p>',
                groups: ['Земледелие'],
                types: ['Интерактив'],
                tags: ['календарь'],
                route: '/nature/calendar/intro',
            },
            {
                number: 7,
                name: 'Песчаные бури',
                duration: 15,
                descr: `<ul>
            <li>Когда возникают песчаные бури</li>
            <li>Как они называются</li>
            <li>Чем опасны песчаные бури</li>
          </ul>`,
                result: '<p>Спасаться от песчаной бури</p>',
                groups: ['Земледелие'],
                types: ['Интерактив'],
                tags: ['природа'],
                route: '/nature/sandstorm/intro',
            },
        ],
    },
    {
        number: 2,
        name: 'Великий дом',
        image: ModuleImg2,
        descr: `<p>
        В этом модуле ты узнаешь об основных этапах развития египетского государства – периодах его расцвета и упадка,
        поймешь, какой властью обладал фараон и различные чиновники, а также, почему погибла Египетская цивилизация.
      </p>`,
        themes: [
            {
                number: 1,
                name: 'Как называли египтяне свою страну',
                descr: `<ul>
            <li>Кто такой Манефон: где жил, чем занимался</li>
            <li>Три самоназвания страны в дельте Нила</li>
          </ul>`,
                result: '<p>Соотносить самоназвания страны с их значениями</p>',
                groups: ['Этапы развития Древнего Египта'],
                types: ['Интерактив'],
                tags: ['самоназвания'],
                route: '/large-home/sebennite/intro',
            },
            {
                number: 2,
                name: 'Этапы государственности',
                descr: `<ul>
            <li>На какие периоды принято делить историю государства Древнего Египта</li>
            <li>Длительность каждого периода</li>
          </ul>`,
                result: '<p>Располагать этапы развития Древнего Египта в хронологической последовательности</p>',
                groups: ['Этапы развития Древнего Египта'],
                types: ['Интерактив'],
                tags: ['государство'],
                route: '/large-home/stages-statehood',
            },
            {
                number: 3,
                name: 'Раннее царство',
                descr: `<ul>
            <li>Кто был фараоном первой династии, и чем он знаменит</li>
            <li>Как называлась первая и современная столицы Египта</li>
            <li>Достижения Раннего царства</li>
            <li>Жизнь бедуинов</li>
          </ul>`,
                groups: ['Этапы развития Древнего Египта'],
                types: ['Интерактив'],
                tags: ['государство', 'фараон', 'бедуины', 'столица'],
                route: '/large-home/early-kingdom/main',
            },
            {
                number: 4,
                name: 'Сооружения Древнего Египта',
                descr: `<ul>
            <li>Как выглядели укрепления первой столицы Египта</li>
            <li>Погребальные сооружения - мастабы</li>
          </ul>`,
                groups: ['Этапы развития Древнего Египта'],
                tags: ['мастаба', 'укрепления', 'погребения'],
                route: '/large-home/white-walls',
            },
            {
                number: 5,
                name: 'Оросительная система',
                descr: '<p>Для чего нужна дамба</p>',
                groups: ['Этапы развития Древнего Египта'],
                tags: ['земледелие', 'дамба'],
                route: '/large-home/irrigation',
            },
            {
                number: 6,
                name: 'Древнее царство',
                descr: `<ul>
            <li>Легко ли быть фараоном</li>
            <li>Кто помогал фараону управлять государством</li>
            <li>Что изменилось в период раздробленности</li>
            <li>Что пришло на смену мастабам</li>
          </ul>`,
                groups: ['Этапы развития Древнего Египта'],
                tags: ['погребения', 'фараон', 'государство', 'раздробленность'],
                route: '/large-home/ancient-kingdom',
            },
            {
                number: 7,
                name: 'Среднее царство',
                descr: `<ul>
            <li>Кто основал новую династию и вернул величие Египта</li>
            <li>Какие центры боролись за власть</li>
            <li>Кто такие гиксосы и почему им удалось завоевать Египет</li>
          </ul>`,
                result: '<p>Использовать инструмент археолога - кисть</p>',
                groups: ['Этапы развития Древнего Египта'],
                types: ['Интерактив'],
                tags: ['раздробленность', 'государство', 'гиксосы'],
                route: '/large-home/middle-kingdom',
            },
            {
                number: 8,
                name: 'Новое царство',
                descr: `<ul>
            <li>Кто является самым известным фараоном нового царства (Тутанхамон)</li>
            <li>Что такое катастрофа бронзового века</li>
          </ul>`,
                groups: ['Этапы развития Древнего Египта'],
                tags: ['государство', 'фараон'],
                route: '/large-home/new-kingdom',
            },
            {
                number: 9,
                name: 'Позднее царство',
                descr: '<p>Кто завоевал Египет, и к кому перешла власть фараонов</p>',
                groups: ['Этапы развития Древнего Египта'],
                tags: ['государство', 'знать'],
                route: '/large-home/later-kingdom',
            },
            {
                number: 10,
                name: 'Происхождение государства',
                descr: `<ul>
            <li>Что такое государство</li>
            <li>Классовое рабовладельческое общество</li>
            <li>Кто такие номархи</li>
            <li>Что являлось символами правителей Верхнего и Нижнего Египта</li>
          </ul>`,
                result: `<ul>
            <li>Конструировать определение понятия “класс”</li>
            <li>Определять по древнеегипетским изображениям оригинальную табличку</li>
            <li>Собирать пазлы с картой Египта</li>
            <li>Определять границы Верхнего и Нижнего Египта</li>
          </ul>`,
                types: ['Игра', 'Интерактив'],
                groups: ['Этапы развития Древнего Египта'],
                tags: ['государство', 'знать', 'символы власти'],
                route: '/large-home/origin-of-the-state/intro',
            },
            {
                number: 11,
                name: 'Система управления после объединения<br />Верхнего и Нижнего Египта',
                descr: `<ul>
            <li>Каковы представления египтян о могуществе фараона</li>
            <li>Как выглядел символ власти фараона</li>
            <li>Жизнь фараона в свободное время</li>
            <li>Что входило в обязанности наместников, чати, стражников</li>
          </ul>`,
                result: '<p>Соотносить функции древнеегипетских чиновников</p>',
                types: ['Комикс', 'Интерактив'],
                groups: ['Этапы развития Древнего Египта'],
                tags: ['фараон', 'знать', 'символы власти'],
                route: '/large-home/management-system/intro',
            },
        ],
    },
    {
        number: 3,
        name: 'Кому в Египте жилось хорошо',
        image: ModuleImg3,
        descr: `<p>
        В этом модуле ты проведешь настоящее расследование по поиску украденных вещей писца, в ходе которого узнаешь,
        чем занимались, во что одевались, что ели, во что играли и чем болели разные жители Древнего Египта: от раба до фараона.
      </p>`,
        themes: [
            {
                number: 1,
                name: 'Социальная структура общества',
                descr: `<ul>
            <li>Чем занимались жители Синайского полуострова в древности</li>
            <li>Как связаны занятия с социальным статусом человека</li>
          </ul>`,
                result: '<p>Конструировать социальную пирамиду древнеегипетского общества</p>',
                types: ['Интерактив'],
                groups: ['Общество'],
                tags: ['занятия'],
                route: '/who-had-a-good-life-in-egypt/sinai/map',
            },
            {
                number: 2,
                name: 'Фараон',
                descr: `<ul>
            <li>Почему и как поклонялись фараону</li>
            <li>Как жили фараоны</li>
            <li>Из чего состоял наряд фараона</li>
          </ul>`,
                result: '<p>Реконструировать один день из жизни фараона</p>',
                types: ['Комикс', 'Интерактив'],
                groups: ['Общество'],
                tags: ['фараон', 'ритуалы', 'одежда'],
                route: '/who-had-a-good-life-in-egypt/pharaoh/pharaoh-intro',
            },
            {
                number: 3,
                name: 'Антропологическая реконструкция',
                descr: `<ul>
            <li>Что делают антропологи, какой вклад внес Михаил Герасимов</li>
            <li>Что рассказали кости Тутанхамона и Рамзеса Второго</li>
          </ul>`,
                groups: ['Общество'],
                tags: ['фараон', 'реконструкция'],
                route: '/who-had-a-good-life-in-egypt/pharaoh-was-human-too/0',
            },
            {
                number: 4,
                name: 'Чати',
                descr: `<ul>
            <li>Кто мог занять высшую должность</li>
            <li>Чем занимались чати</li>
            <li>Как выглядела гробница и усадьба вельможи</li>
            <li>Во что одевался вельможа и как он отдыхал</li>
          </ul>`,
                result: `<ul>
            <li>Восстанавливать росписи гробницы с помощью инструмента “Реставратор”</li>
            <li>Анализировать текстовый источник</li>
          </ul>`,
                types: ['Комикс', 'Интерактив'],
                groups: ['Общество'],
                tags: ['знать', 'жилище', 'погребение', 'управление', 'одежда', 'отдых', 'государство'],
                route: '/who-had-a-good-life-in-egypt/chaty/about-chaty',
            },
            {
                number: 5,
                name: 'Земледельцы и ремесленники',
                descr: `<ul>
            <li>Какими правами и обязанностями обладали</li>
            <li>Как они могли превратиться в рабов</li>
            <li>Как был организован быт простолюдинов</li>
          </ul>`,
                result: `<ul>
            <li>Играть в древнюю игру “Сенет”</li>
            <li>Анализировать визуальные источники</li>
          </ul>`,
                groups: ['Общество'],
                tags: ['жилище', 'рабы', 'еда', 'земледелие', 'отдых'],
                route: '/who-had-a-good-life-in-egypt/artisans/intro/',
            },
            {
                number: 6,
                name: 'Жрецы',
                descr: `<ul>
            <li>Как становились жрецами</li>
            <li>Где жили и работали жрецы</li>
          </ul>`,
                result: '<p>Реконструировать храм жреца</p>',
                types: ['Интерактив'],
                groups: ['Общество'],
                tags: ['жрецы', 'храм', 'государство'],
                route: '/who-had-a-good-life-in-egypt/priests/intro/',
            },
            {
                number: 7,
                name: 'Писцы',
                descr: `<ul>
            <li>Чем занимались писцы</li>
            <li>Условия их работы</li>
            <li>Как выглядели рабочие писцовые принадлежности</li>
          </ul>`,
                result: '<p>Идентифицировать письменные принадлежности среди других древнеегипетских атрибутов</p>',
                types: ['Интерактив'],
                groups: ['Общество'],
                tags: ['государство', 'писцы', 'письменность'],
                route: '/who-had-a-good-life-in-egypt/pyramid/',
            },
        ],
    },
    {
        number: 4,
        name: 'Грозные завоеватели',
        image: ModuleImg4,
        descr: `<p>
        В этом модуле ты узнаешь, почему на протяжении долгого времени египетская армия побеждала своих противников.
        Ты даже сможешь организовать войско, пока фараон отдыхает.
      </p>`,
        roles: ['ADMIN'],
        themes: [
            {
                number: 1,
                name: 'Великая держава',
                descr: `<ul>
            <li>Что такое держава</li>
            <li>С кем воевал и какие территории присоединил Древний Египет</li>
          </ul>`,
                result: `<ul>
            <li>Находить на карте соседей и врагов</li>
            <li>Определять направления внешней политики</li>
          </ul>`,
                types: ['Интерактив'],
                groups: ['Армия'],
                tags: ['внешняя политика'],
                route: '/formidable-conquerors/intro/map',
            },
            {
                number: 2,
                name: 'Вооруженные силы',
                descr: `<ul>
            <li>Что такое армия, ополчение, наемники</li>
            <li>Какова структура армии</li>
            <li>Принцип формирования египетских вооруженных сил</li>
            <li>Вооружение, экипировка воина, тактика боя, награды</li>
          </ul>`,
                result: '<p>Анализировать визуальный источник и  соотносить название родов войск с изображениями</p>',
                types: ['Комикс', 'Интерактив'],
                groups: ['Армия'],
                tags: ['вооружение', 'экипировка', 'тактика боя', 'символы'],
                route: '/formidable-conquerors/army-structure/start',
            },
        ],
    },
    {
        number: 5,
        name: 'Тайна за семью печатями',
        image: ModuleImg5,
        descr: `<p>
        В этом модуле ты узнаешь, как был устроен мир в представлении древних египтян, как и зачем они изготавливали
        мумию, как менялись иероглифы, а также найдешь ответы на многие вопросы: Что было «за душой» жителей этой цивилизации?
        Чему и как учили в Древнем Египте? Что умели лечить древнеегипетские доктора? Какой была мода в далеком египетском прошлом?
        Что слушали меломаны того времени? Какие талисманы приносили удачу?
      </p>`,
        roles: [],
        themes: [
            {
                number: 1,
                name: 'Картина мира',
                descr: `<ul>
            <li>Что такое картина мира, и из чего она состоит</li>
            <li>Как устроен мир в представлении древних египтян</li>
            <li>Верования: что такое политеизм и как божества связаны с природными явлениями</li>
            <li>Миф о сотворении мира</li>
          </ul>`,
                result: `<ul>
          <li>Соотносить богов с их местом в мире</li>
          <li>Соотносить описание божества с его изображением</li>
        </ul>`,
                types: ['Комикс', 'Интерактив', 'Игра'],
                groups: ['Мировоззрение'],
                tags: ['верования', 'божества', 'природа'],
                route: '',
            },
            {
                number: 2,
                name: 'Религиозные ритуалы',
                descr: `<ul>
            <li>Обряды</li>
            <li>Реформа Эхнатона: как фараон пытался изменить религиозные взгляды</li>
          </ul>`,
                result: '<p>Анализ текстового источника</p>',
                groups: ['Мировоззрение'],
                tags: ['ритуалы', 'фараон', 'верования'],
                route: '',
            },
            {
                number: 3,
                name: 'Концепция души',
                descr: `<ul>
            <li>Какова структура души</li>
            <li>Что такое мумификация</li>
            <li>Представления о смерти</li>
            <li>Устройство саркофага</li>
            <li>Погребальные обряды</li>
            <li>Представления о загробной жизни</li>
          </ul>`,
                result: '<p>Реконструировать погребальный обряд</p>',
                groups: ['Мировоззрение'],
                types: ['Комикс', 'Интерактив'],
                tags: ['верования', 'мумификация', 'ритуалы', 'погребения'],
                route: '',
            },
            {
                number: 4,
                name: 'Амулеты и талисманы',
                descr: `<ul>
            <li>Чем отличаются амулеты от талисманов</li>
            <li>Функции амулетов и талисманов</li>
          </ul>`,
                groups: ['Мировоззрение'],
                types: ['Интерактив'],
                tags: ['верования', 'символы', 'фауна', 'фараон'],
                route: '',
            },
            {
                number: 5,
                name: 'Священные животные',
                descr: '<p>Каких животных почитали древние египтяне и почему</p>',
                groups: ['Мировоззрение'],
                types: ['Интерактив'],
                tags: ['верования', 'божества', 'фауна', 'символы', 'природа'],
                route: '',
            },
            {
                number: 6,
                name: 'Письменность',
                descr: `<ul>
            <li>Как писали иероглифы</li>
            <li>Развитие письменности</li>
            <li>Как расшифровали иероглифы</li>
          </ul>`,
                result: '<p>Писать имя иероглифами</p>',
                groups: ['Образование'],
                types: ['Интерактив'],
                tags: ['иероглифы', 'письменность'],
                route: '',
            },
            {
                number: 7,
                name: 'Образование и научные знания',
                descr: `<ul>
            <li>Какие были школы, и чему в них учили</li>
            <li>Как выглядела первая карта звездного неба</li>
            <li>Как определяли время</li>
            <li>Как считали жители долины Нила</li>
            <li>Что лечили древние врачи</li>
            <li>Чем чистили зубы, и как выглядели первые солнцезащитные очки</li>
          </ul>`,
                result: `<ul>
          <li>Определять время по солнечным часам</li>
          <li>Определять числа по древним символам</li>
        </ul>`,
                groups: ['Образование'],
                types: ['Интерактив', 'Комикс'],
                tags: ['школа', 'символы', 'здоровье', 'жрецы'],
                route: '',
            },
            {
                number: 8,
                name: 'Одежда',
                descr: `<ul>
            <li>Что такое красота</li>
            <li>Из каких материалов изготавливали одежду и как ее украшали</li>
            <li>Как по одежде и обуви можно определить социальный статус человека</li>
          </ul>`,
                result: '<p>Подбирать цвета для древнеегипетских орнаментов/узоров</p>',
                groups: ['Красота'],
                types: ['Интерактив'],
                tags: ['символы', 'одежда', 'обувь', 'общество'],
                route: '',
            },
            {
                number: 9,
                name: 'Прически',
                descr: `<ul>
            <li>Что такое красота</li>
            <li>Популярная форма прически</li>
            <li>Использование париков</li>
            <li>Как по прическе можно определить социальный статус человека</li>
          </ul>`,
                groups: ['Красота'],
                tags: ['общество', 'парики', 'прически'],
                route: '',
            },
            {
                number: 10,
                name: 'Косметика',
                descr: `<ul>
            <li>Что такое красота</li>
            <li>Зачем нужна косметика живым и мертвым</li>
            <li>Что использовали в качестве косметических средств</li>
            <li>Каков был маникюр царицы Нефертити</li>
            <li>Как и для чего удаляли волосы на теле</li>
          </ul>`,
                groups: ['Красота'],
                tags: ['косметика', 'ритуалы'],
                route: '',
            },
            {
                number: 11,
                name: 'Архитектура',
                descr: `<ul>
            <li>Черты египетских храмов</li>
            <li>Как строили пирамиды</li>
            <li>Как устроена пирамида Тутанхамона</li>
            <li>Кто грабил пирамиды</li>
          </ul>`,
                result: `<ul>
          <li>Выделять черты египетских храмов</li>
          <li>Реконструировать структуру храмовой постройки</li>
        </ul>`,
                groups: ['Архитектура'],
                types: ['Интерактив'],
                tags: ['храмы', 'пирамиды', 'фараон', 'погребение'],
                route: '',
            },
            {
                number: 12,
                name: 'Музыка',
                descr: `<ul>
            <li>Зачем нужна музыка</li>
            <li>Виды музыкальных инструментов</li>
          </ul>`,
                result: '<p>Воспроизводить древние ритмы на барабане</p>',
                groups: ['Музыка'],
                types: ['Интерактив'],
                tags: ['ритуалы', 'инструменты', 'отдых'],
                route: '',
            },
            {
                number: 13,
                name: 'Бытовые вещи',
                descr: `<ul>
            <li>Способы обработки металлов</li>
            <li>Резьба</li>
            <li>Чем отличается египетское стекло</li>
            <li>Как и какую мебель изготавливали</li>
          </ul>`,
                groups: ['Быт'],
                tags: ['занятия', 'мебель'],
                route: '',
            },
        ],
    },
];

export const themes = prepareThemesData(data);

export const modules = prepareModulesData(data);

const options = {
    minMatchCharLength: 4,
    threshold: 0.1,
    ignoreLocation: true,
    keys: ['name', 'descr', 'result', 'groups', 'types', 'tags'],
};

const searchIndex = Fuse.createIndex(options.keys, themes);
export const fuse = new Fuse(themes, options, searchIndex);
