import { Container } from 'components/Container';
import { laptopDownMedia } from 'styles/helpers';
import styled, { css } from 'styled-components';

export const Header = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    ${laptopDownMedia(css`
        background: #ffffff;
        border-bottom: 1px solid #e0e0e0;
    `)}
`;

export const HeaderContainer = styled(Container)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 48px;
    padding-bottom: 48px;
    z-index: 200;

    ${laptopDownMedia(css`
        padding-top: 14px;
        padding-bottom: 14px;
    `)}
`;

export const BlurBg = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 200%;
    background: ${({ theme }) => theme.colors.bg};
    filter: blur(32px);
    z-index: 10;
`;
