import { Container } from 'components/Container';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    transition: opacity 0.15s ease-out;
    z-index: 9999;
`;

export const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
