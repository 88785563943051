import { PromoVideo } from 'components/PromoVideo';
import { PromoVideoPopup } from 'components/PromoVideoPopup';
import { laptopUp } from 'config';
import React, { CSSProperties, memo, ReactNode, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll';
import { Transition, TransitionStatus } from 'react-transition-group';
import { getProgress } from 'store/common';
import { goToLesson } from 'utils/helpers';
import { useMatchMedia, useScrolledWrapper } from 'utils/hooks';
import * as C from 'styles/components';
import VideoMov from 'assets/video/bastet.mov';
import VideoWebm from 'assets/video/bastet.webm';
import { ButtonSizes } from 'components/Button';
import BastetPoster from 'assets/images/bastet-poster.webp';
import * as S from './styles';

const animation: Record<TransitionStatus, { opacity: number }> = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
};

export const Promo = memo(() => {
    const [showVideo, setShowVideo] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const laptopMatch = useMatchMedia(laptopUp);
    const position = useScrolledWrapper(ref, document.documentElement.clientHeight);
    const progress = useSelector(getProgress);

    const promoContainerAnimation = (): CSSProperties | undefined => {
        if (!laptopMatch) return undefined;

        if (position >= 0 && position <= 0.45) {
            return { opacity: 1 - position / 0.45 };
        }

        return { opacity: 0 };
    };

    const videoContainerAnimation = (): CSSProperties | undefined => {
        if (!laptopMatch) return undefined;

        if (position >= 0 && position <= 0.5) {
            return { transform: `translateY(${100 - (position / 0.5) * 100}%)` };
        }

        return { transform: `translateY(0)` };
    };

    let descr: ReactNode;

    if (laptopMatch) {
        descr = (
            <>
                <p>
                    Лаборатория геймификации Сбера приглашает вас отправиться
                    <br /> в магический мир цивилизации Древнего Египта.
                </p>
                <p>
                    Материалы рассчитаны на учеников 5 класса, однако
                    <br /> они будут полезны и для более широкой аудитории.
                </p>
                <S.StyledButton
                    iconLeft="play"
                    size={ButtonSizes.l}
                    showDivider={false}
                    withShadow
                    onClick={() => goToLesson(progress?.$currentPath)}
                >
                    Играть
                </S.StyledButton>
            </>
        );
    } else {
        descr = (
            <S.StyledButton
                iconLeft="camera"
                size={ButtonSizes.l}
                showDivider={false}
                withShadow
                onClick={() => setShowVideo(true)}
            >
                Посмотреть видео
            </S.StyledButton>
        );
    }

    if (laptopMatch === undefined) {
        descr = undefined;
    }

    return (
        <>
            <S.Promo ref={ref}>
                <S.Sticky>
                    <C.Background />
                    <S.MobileShadow />
                    <S.PromoContainer style={promoContainerAnimation()} fullHeight>
                        <S.Head as="h1">
                            Интерактивное
                            <br /> путешествие по
                            <br /> Древнему Египту
                        </S.Head>
                        <S.Descr>{descr}</S.Descr>
                        <S.PersonWrapper>
                            <S.Person poster={BastetPoster} width={800} height={1080} autoPlay loop muted playsInline>
                                <source src={VideoMov} type="video/quicktime" />
                                <source src={VideoWebm} type="video/webm" />
                            </S.Person>
                        </S.PersonWrapper>
                    </S.PromoContainer>
                    <S.VideoContainer style={videoContainerAnimation()} fullHeight>
                        <PromoVideo />
                    </S.VideoContainer>
                    <Link to="all-inclusive" smooth>
                        <S.NextScreenIcon glyph="arrow-bottom" size={24} />
                    </Link>
                </S.Sticky>
            </S.Promo>
            <Transition nodeRef={ref} in={showVideo} timeout={150} unmountOnExit>
                {(state) => (
                    <PromoVideoPopup ref={ref} style={animation[state]} onOutsideClick={() => setShowVideo(false)} />
                )}
            </Transition>
        </>
    );
});
