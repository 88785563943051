import { Content } from 'containers/Content';
import React, { useEffect, useRef, useState } from 'react';
import { useMatchMedia } from 'utils/hooks';
import { laptopUp } from 'config';
import { Header } from 'components/Header';
import { Promo } from 'components/Promo';
import { MobileDescription } from 'components/MobileDescription';
import { MobileGetLead } from 'components/MobileGetLead';
import { AllInclusive } from 'components/AllInclusive';
import { GetLead } from 'components/GetLead';
import { Footer } from 'components/Footer';

const Home: React.FC = () => {
    const laptopMatch = useMatchMedia(laptopUp);
    const [isPromoScrolled, setIsPromoScrolled] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const observer = useRef<IntersectionObserver>();

    useEffect(() => {
        const callback = (entries: IntersectionObserverEntry[]) => {
            if (!entries[0].isIntersecting && entries[0].boundingClientRect.top < 0) {
                setIsPromoScrolled(true);
            } else {
                setIsPromoScrolled(false);
            }
        };

        observer.current = new IntersectionObserver(callback);

        if (ref.current) {
            observer.current?.observe(ref.current);
        }
    }, []);

    return (
        <>
            <Header showBg={isPromoScrolled} />
            <Promo />
            <div ref={ref} />
            {!laptopMatch && (
                <>
                    <MobileDescription />
                    <MobileGetLead
                        title="врывайся в игру!"
                        descr={
                            'Прохождение курса доступно только на устройствах с большей диагональю, например, компьютере или ноутбуке.\n\nОставьте свою почту и получите первыми доступ к материалам.'
                        }
                    />
                </>
            )}
            <AllInclusive />
            <Content />
            {laptopMatch ? (
                <GetLead />
            ) : (
                <MobileGetLead
                    title="оставьте свою почту и получите первыми доступ к материалам"
                    descr="Участие в пилоте бесплатно"
                />
            )}
            <Footer />
        </>
    );
};

export { Home };
