import { ThemeCard } from 'components/ThemeCard';
import { Input } from 'components/Input';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';
import styled, { css } from 'styled-components';
import { Container } from 'components/Container';
import { Title } from 'styles/components';
import { Select } from 'components/Select';
import { MobileDevicesComment } from 'components/MobileDevicesComment';

export const ContentContainer = styled(Container)`
    ${laptopDownMedia(css`
        background: #ffffff;
        padding-top: 24px;
        margin-bottom: 71px;
    `)}
`;

export const Head = styled(Title)`
    font-size: 36px;
    margin-bottom: 24px;

    ${laptopUpMedia(css`
        display: none;
    `)}
`;

export const Filter = styled.div`
    display: flex;
    column-gap: 8px;
    margin-bottom: 36px;

    ${laptopUpMedia(css`
        column-gap: 16px;
        margin-bottom: 32px;
    `)}
`;

export const StyledSelect = styled(Select)`
    max-width: 240px;
`;

export const StyledInput = styled(Input)`
    width: 100%;

    ${laptopDownMedia(css`
        display: none;
    `)}

    ${laptopUpMedia(css`
        max-width: 290px;
        margin-left: auto;
    `)}
`;

export const Modules = styled.div`
    background: #ffffff;

    ${laptopDownMedia(css`
        border-top: ${({ theme }) => `1px solid ${theme.colors.veryLightGray}`};
        margin-left: -24px;
        margin-right: -25px;
        padding-left: 24px;
        padding-right: 24px;
    `)}

    ${laptopUpMedia(css`
        border-radius: 24px;
    `)}
`;

export const StyledMobileDevicesComment = styled(MobileDevicesComment)`
    margin: 16px 24px 16px 0;
`;

export const StyledThemeCard = styled(ThemeCard)`
    padding-left: 0;
`;

export const NotFound = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1;
    color: rgba(52, 58, 69, 0.2);

    ${laptopUpMedia(css`
        font-size: 90px;
    `)}

    ${laptopDownMedia(css`
        padding-top: 20px;
        padding-bottom: 20px;
    `)}
`;
