import { rgba } from 'polished';
import styled from 'styled-components';

export const Popup = styled.div`
    border-radius: 24px;
    background: #ffffff;

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
        transition: opacity 200ms ease-in-out;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${rgba('#000000', 0.6)};
    z-index: 99999;

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
        transition: opacity 200ms ease-in-out;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
`;
