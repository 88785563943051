import { Text, Title } from 'styles/components';
import styled, { css } from 'styled-components';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';

interface ThemeProps {
    locked?: boolean;
}

interface TagsProps {
    isShowDuration?: boolean;
}

export const Theme = styled.section<ThemeProps>`
    display: grid;
    grid-template: repeat(3, min-content) / 32px 1fr max-content;
    grid-template-areas:
        'tags   .     duration'
        'number head  head'
        'descr  descr descr';

    gap: 24px 8px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.veryLightGray}`};
    opacity: ${({ locked }) => (locked ? 0.6 : 1)};
    pointer-events: ${({ locked }) => (locked ? 'none' : 'auto')};
    padding: 28px 24px 28px;

    &:last-child {
        border: 0;
    }

    ${laptopUpMedia(css`
        display: grid;
        grid-template: repeat(2, min-content) / 44px max-content 1fr min-content;
        grid-template-areas:
            'number head  tags  button'
            'descr  descr descr .     ';
        align-items: center;
        gap: 24px 16px;
        padding: 32px 48px 32px;
    `)}
`;

export const Number = styled.div`
    grid-area: number;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.bg};
    font-size: ${({ theme }) => theme.fontSize.l}px;
    font-weight: 700;

    ${laptopUpMedia(css`
        height: 44px;
    `)}
`;

export const Head = styled(Title)`
    grid-area: head;
    font-size: ${({ theme }) => `${theme.fontSize.xl}px`};
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: 1.2;

    ${laptopDownMedia(css`
        align-self: center;
    `)}
`;

export const Duration = styled.div`
    font-size: ${({ theme }) => `${theme.fontSize.m}px`};
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 400;

    ${laptopDownMedia(css`
        grid-area: duration;
    `)}
`;

export const Tags = styled.div<TagsProps>`
    grid-area: tags;
    display: flex;
    column-gap: 12px;
    align-self: start;
    margin-top: ${({ isShowDuration }) => (isShowDuration ? 4 : 13)}px;
`;

export const ButtonWrapper = styled.div`
    grid-area: button;

    ${laptopDownMedia(css`
        display: none;
    `)}
`;

export const Descr = styled(Text)`
    grid-area: descr;
    align-self: start;

    ${laptopUpMedia(css`
        margin-right: 100px;
    `)}
`;

export const Result = styled(Text)`
    background-color: ${({ theme }) => theme.colors.bg};
    border-radius: 12px;
    padding: 24px;
    margin-top: 32px;

    ${laptopUpMedia(css`
        margin-top: 24px;
    `)}
`;

export const ResultHead = styled(Title)`
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
`;
