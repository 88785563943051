import styled from 'styled-components';

export const ConfirmLogout = styled.div`
    width: 100vw;
    max-width: 520px;
    box-sizing: border-box;
    padding: 40px;
`;

export const Title = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-size: ${({ theme }) => theme.fontSize.xl}px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 12px;
`;

export const Descr = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-size: ${({ theme }) => theme.fontSize.m}px;
    margin-bottom: 24px;
`;

export const Buttons = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
`;
