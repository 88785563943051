import { Title } from 'styles/components';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const MobileDevicesComment = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.accent};
    background: ${({ theme }) => transparentize(0.9, theme.colors.accent)};
    border-radius: 18px;
    font-size: ${({ theme }) => theme.fontSize.m}px;
    padding: 16px;
`;

export const Head = styled(Title)`
    font-size: ${({ theme }) => theme.fontSize.l}px;
    color: ${({ theme }) => theme.colors.accent};
    margin-bottom: 16px;
`;
