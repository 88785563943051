import { Button, ButtonColors, ButtonVariants } from 'components/Button';
import { Tag, TagVariants } from 'components/Tag';
import { laptopUp } from 'config';
import { Theme } from 'data';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProgress } from 'store/common';
import { goToLesson, kebabToCamelCase } from 'utils/helpers';
import { useIsAccess, useMatchMedia } from 'utils/hooks';
import * as S from './styles';

interface ThemeProps {
    data: Theme;
    activeType?: string;
    className?: string;
    onTypeSelect: (value: string) => void;
}

export const ThemeCard = memo((props: ThemeProps) => {
    const { data, activeType, className, onTypeSelect } = props;

    const progress = useSelector(getProgress);
    const laptopMatch = useMatchMedia(laptopUp);
    const [currentPath, setCurrentPath] = useState<string | undefined>(undefined);
    const [finalProgress, setFinalProgress] = useState(0);
    const [buttonText, setButtonText] = useState('Начать');
    const [buttonVariant, setButtonVariant] = useState(ButtonVariants.solid);
    const isAccess = useIsAccess(data?.roles);

    useEffect(() => {
        let moduleAlias = data.route.split('/')[1];
        let themeAlias = data.route.split('/')[2];

        if (moduleAlias && themeAlias) {
            moduleAlias = kebabToCamelCase(moduleAlias);
            themeAlias = kebabToCamelCase(themeAlias);

            if (progress?.[moduleAlias]?.[themeAlias]) {
                setCurrentPath(progress[moduleAlias][themeAlias].$currentPath);
                setFinalProgress(progress[moduleAlias][themeAlias].$numericStatus * 100);
            }
        }
    }, [data, progress]);

    useEffect(() => {
        let text = 'Начать';
        let variant = ButtonVariants.solid;

        if (finalProgress === 100) {
            text = 'Пройдено';
            variant = ButtonVariants.outline;
        } else if (finalProgress !== 0) {
            text = 'Продолжить';
            variant = ButtonVariants.progress;
        }

        setButtonText(text);
        setButtonVariant(variant);
    }, [data.route, finalProgress]);

    return (
        <S.Theme className={className} locked={!isAccess}>
            <S.Tags isShowDuration={!!data.duration}>
                {data.types?.map((type) => (
                    <Tag
                        key={type}
                        variant={TagVariants.light}
                        active={activeType === type}
                        onClick={() => onTypeSelect(type)}
                    >
                        {type}
                    </Tag>
                ))}
            </S.Tags>
            <S.Number>{data.number}</S.Number>
            <S.Head as="h4">
                <span dangerouslySetInnerHTML={{ __html: data.name }} />
                {data.duration && laptopMatch && <S.Duration>~ {data.duration} мин</S.Duration>}
            </S.Head>
            {data.duration && !laptopMatch && <S.Duration>~ {data.duration} мин</S.Duration>}
            <S.ButtonWrapper>
                {isAccess ? (
                    <Button
                        iconLeft={finalProgress === 100 ? 'like' : 'play'}
                        showDivider={false}
                        variant={buttonVariant}
                        progress={finalProgress}
                        uppercase
                        onClick={() => goToLesson(currentPath || data.route)}
                    >
                        {buttonText}
                    </Button>
                ) : (
                    <Button
                        iconLeft="lock"
                        iconColor="#fff"
                        showDivider={false}
                        variant={ButtonVariants.solid}
                        color={ButtonColors.veryLightGray}
                        disabled
                    />
                )}
            </S.ButtonWrapper>
            <S.Descr>
                {data.descr && <div dangerouslySetInnerHTML={{ __html: data.descr }} />}
                {data.result && (
                    <S.Result>
                        <S.ResultHead>Вы научитесь:</S.ResultHead>
                        <div dangerouslySetInnerHTML={{ __html: data.result }} />
                    </S.Result>
                )}
            </S.Descr>
        </S.Theme>
    );
});
