import styled, { css } from 'styled-components';
import { laptopUpMedia } from 'styles/helpers';

export const User = styled.div`
    display: none;

    ${laptopUpMedia(css`
        display: block;
    `)}
`;
