import { StyledVariants } from 'interfaces/StyledVariants';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonColors, ButtonSizes, ButtonVariants } from './types';

interface ButtonProps {
    color: ButtonColors;
    size: ButtonSizes;
    variant: ButtonVariants;
    progress?: number;
    uppercase?: boolean;
    withShadow?: boolean;
}

const sizes: StyledVariants<ButtonSizes> = {
    s: css`
        font-size: ${({ theme }) => theme.fontSize.m}px;
        font-weight: 600;
        border-radius: 36px;
        column-gap: 12px;
        height: 35px;
        padding: 0 16px;
    `,
    m: css`
        font-size: ${({ theme }) => theme.fontSize.xs}px;
        font-weight: 700;
        border-radius: 12px;
        column-gap: 12px;
        height: 44px;
        padding: 0 18px;
    `,
    l: css`
        font-size: ${({ theme }) => theme.fontSize.l}px;
        font-weight: 600;
        border-radius: 16px;
        column-gap: 16px;
        height: 58px;
        padding: 0 22px;
    `,
};

export const Button = styled.button<ButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.familySecondary};
    line-height: 1;
    text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
    text-decoration: none;
    box-shadow: ${({ withShadow }) => withShadow && '0 16px 32px rgba(0, 109, 210, 0.3)'};
    border: 0;
    outline: none;
    background: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    transition: 0.3s ease-out;

    &:disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    ${({ variant = ButtonVariants.solid, color = ButtonColors.accent, progress, theme }) => {
        const hoverColorMap: { [key in ButtonColors]?: string } = {
            accent: theme.colors.lightAccent,
        };

        const themeColor = theme.colors[color];
        const hoverColor = hoverColorMap[color];

        switch (variant) {
            case ButtonVariants.solid:
                return css`
                    color: #ffffff;
                    background: ${themeColor};

                    ${hoverColor &&
                    css`
                        &:hover {
                            background: ${hoverColor};
                        }
                    `}
                `;
            case ButtonVariants.outline:
                return css`
                    color: ${themeColor};
                    background: transparent;
                    border: 1px solid ${themeColor};
                `;
            case ButtonVariants.ghost:
                return css`
                    color: ${themeColor};
                    background: transparent;
                `;
            case ButtonVariants.progress:
                return css`
                    color: #fff;
                    text-shadow: 0 0 24px ${transparentize(0.3, '#006DD2')};
                    background-image: linear-gradient(
                        to right,
                        ${themeColor} 0%,
                        ${themeColor} ${progress}%,
                        ${transparentize(0.9, themeColor)} ${progress}%,
                        ${transparentize(0.9, themeColor)} 100%
                    );
                `;
            default:
                return css``;
        }
    }}

    ${({ size }) => sizes[size]}
`;

export const Divider = styled.div<{ size: number }>`
    height: ${({ size }) => `${size}px`};
    opacity: 0.2;
    border-left: 1px solid ${({ theme }) => theme.colors.bg};
`;
