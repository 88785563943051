import { Text } from 'styles/components';
import styled from 'styled-components';

export const Descr = styled(Text)`
    font-family: ${({ theme }) => theme.familySecondary};
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.fontSize.xxs}px;
    font-weight: 500;
    margin-top: 24px;

    a {
        color: ${({ theme }) => theme.colors.gray};
    }
`;

const Info = styled.div`
    font-size: ${({ theme }) => theme.fontSize.m}px;
    text-align: left;
    margin-top: 8px;
`;

export const Success = styled(Info)`
    color: ${({ theme }) => theme.colors.accent};
    max-width: 365px;
    width: 100%;
    text-align: center;
    margin-inline: auto;
`;

export const Error = styled(Info)`
    color: ${({ theme }) => theme.colors.error};
`;
