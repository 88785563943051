import { Text, Title } from 'styles/components';
import styled from 'styled-components';

export const MobileGetLead = styled.section`
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.colors.accent};
    background: #fff;
    padding: 24px 20px 36px;
`;

export const Head = styled(Title)`
    color: ${({ theme }) => theme.colors.accent};
    font-size: 24px;
    margin-bottom: 24px;
`;

export const Descr = styled(Text)`
    white-space: break-spaces;
    margin-bottom: 24px;
`;
