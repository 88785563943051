import { Button, ButtonVariants } from 'components/Button';
import { Icon } from 'components/Icon';
import { Module, Theme } from 'data/';
import React, { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProgress } from 'store/common';
import { baseTheme } from 'styles/theme';
import { Tag, TagVariants } from 'components/Tag';
import * as C from 'styles/components';
import { goToLesson, kebabToCamelCase } from 'utils/helpers';
import { useIsAccess } from 'utils/hooks';
import * as S from './styles';

export interface ModuleProps {
    data: Module;
    children?: (themes: Theme[]) => ReactNode;
    activeGroup?: string;
    enabledFilter: boolean;
    onGroupSelect: (value: string) => void;
}

export const ModuleCard = memo((props: ModuleProps) => {
    const { data, children, activeGroup, enabledFilter, onGroupSelect } = props;

    const progress = useSelector(getProgress);
    const [isShowThemes, setIsShowThemes] = useState(false);
    const [themesHeight, setThemesHeight] = useState(0);
    const [finalProgress, setFinalProgress] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const isAccess = useIsAccess(data?.roles);

    const handlerMoreClick = () => {
        setIsShowThemes((isShowThemes) => !isShowThemes);
    };

    useEffect(() => {
        if (ref.current) {
            const height = ref.current.offsetHeight;
            setThemesHeight(height);
        }
    }, [ref, children]);

    useEffect(() => {
        let moduleAlias = data.themes[0].route.split('/')[1];

        if (moduleAlias) {
            moduleAlias = kebabToCamelCase(moduleAlias);

            if (progress?.[moduleAlias]) {
                setFinalProgress(progress[moduleAlias].$numericStatus * 100);
            }
        }
    }, [data, progress]);

    return (
        <S.Wrapper isShowThemes={isShowThemes}>
            <S.Module inProgress={!!finalProgress}>
                <S.Number>
                    {!isAccess && <Icon glyph="lock" color={baseTheme.colors.lightGray} size={26} />}
                    Модуль {data.number}
                </S.Number>
                {!isAccess && (
                    <S.InDev>
                        <S.InDevBadge>В разработке</S.InDevBadge>
                    </S.InDev>
                )}
                {isAccess && finalProgress > 0 && (
                    <S.Resume>
                        <Button
                            variant={ButtonVariants.progress}
                            progress={finalProgress}
                            iconLeft="play"
                            showDivider={false}
                            onClick={() => goToLesson(progress?.$currentPath)}
                            uppercase
                        >
                            Продолжить
                        </Button>
                    </S.Resume>
                )}
                <S.Head as="h3" dangerouslySetInnerHTML={{ __html: data.name }} />
                <S.Image src={data.image} alt={data.name} />
                {data.descr && <S.Descr dangerouslySetInnerHTML={{ __html: data.descr }} />}
                <S.Tags>
                    {data.groups?.map((group) => (
                        <Tag
                            key={group}
                            variant={TagVariants.dark}
                            active={activeGroup === group}
                            onClick={() => onGroupSelect(group)}
                        >
                            {group}
                        </Tag>
                    ))}
                </S.Tags>
                {isAccess && finalProgress > 0 && (
                    <S.ProgressInfo>
                        <C.ProgressBar value={finalProgress} max={100} />
                        <S.ProgressValue>{Math.floor(finalProgress)}%</S.ProgressValue>
                    </S.ProgressInfo>
                )}
                {children && (
                    <S.More onClick={handlerMoreClick}>
                        <S.MoreIcon
                            glyph="angle-down"
                            size={20}
                            color={baseTheme.colors.lightGray}
                            mirror={isShowThemes || enabledFilter}
                        />
                        {isShowThemes || enabledFilter ? 'Скрыть содержание модуля' : 'Содержание модуля'}
                    </S.More>
                )}
            </S.Module>
            {children && (
                <S.ThemesWrapper showed={isShowThemes || enabledFilter} height={themesHeight}>
                    <div ref={ref}>{children(data.themes)}</div>
                </S.ThemesWrapper>
            )}
        </S.Wrapper>
    );
});
