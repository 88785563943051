import { ConfirmLogout } from 'components/ConfirmLogout';
import { Logout } from 'components/Logout';
import { Popup } from 'components/Popup';
import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/auth';
import Avatar from 'assets/images/avatar.png';
import Avatar2x from 'assets/images/avatar@2x.png';
import * as S from './styles';

interface UserNameProps {
    variant: 'dark' | 'light';
}

export const UserName = memo((props: UserNameProps) => {
    const { variant } = props;

    const [isShowLogout, setIsShowLogout] = useState(false);
    const [isShowConfirmLogout, setIsShowConfirmLogout] = useState(false);
    const authUser = useSelector(getAuthUser);

    const handlerOpenLogoutPopup = useCallback(() => {
        setIsShowLogout(true);
    }, []);

    const handlerCloseLogoutPopup = useCallback(() => {
        setIsShowLogout(false);
    }, []);

    const handlerOpenConfirmLogoutPopup = useCallback(() => {
        setIsShowConfirmLogout(true);
        setIsShowLogout(false);
    }, []);

    const handlerCloseConfirmLogoutPopup = useCallback(() => {
        setIsShowConfirmLogout(false);
    }, []);

    return (
        <S.Wrapper>
            <S.UserName variant={variant} onClick={handlerOpenLogoutPopup}>
                Привет, {authUser?.name}
                <S.Avatar src={Avatar} srcSet={`${Avatar2x} 2x`} alt="" />
            </S.UserName>
            <S.StyledPopup isShow={isShowLogout} overlay={false} onClose={handlerCloseLogoutPopup}>
                <Logout onConfirm={handlerOpenConfirmLogoutPopup} />
            </S.StyledPopup>
            <Popup isShow={isShowConfirmLogout} onClose={handlerCloseConfirmLogoutPopup}>
                <ConfirmLogout onCancel={handlerCloseConfirmLogoutPopup} />
            </Popup>
        </S.Wrapper>
    );
});
