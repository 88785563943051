import React, { memo, ReactNode, SelectHTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { InputColors, InputSizes, InputVariants } from 'components/Input';
import { useTheme } from 'styled-components';
import { useClickOutside } from 'utils/hooks';
import { SelectContext } from './SelectContext';
import * as S from './styles';

type HTMLSelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onSelect'>;

interface SelectProps extends HTMLSelectProps {
    value?: string;
    placeholder?: string;
    variant?: InputVariants;
    inputSize?: InputSizes;
    color?: InputColors;
    className?: string;
    children?: ReactNode;
    onSelect?: (value: string) => void;
}

const animation: Record<TransitionStatus, { opacity: number }> = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
};

// TODO: Сделать закрытие при повторном клике на поле
export const Select = memo((props: SelectProps) => {
    const { placeholder, inputSize, variant, color, value = '', children, className, onSelect } = props;

    const ref = useRef<HTMLUListElement>(null);
    const [isShow, setIsShow] = useState(false);

    const theme = useTheme();

    const handlerSelect = useCallback(
        (value: string) => {
            onSelect?.(value);
            setIsShow(false);
        },
        [onSelect]
    );

    const handlerClick = () => {
        setIsShow((isShow) => !isShow);
    };

    const contextValue = useMemo(
        () => ({
            currentValue: value,
            handlerSelect,
        }),
        [handlerSelect, value]
    );

    useClickOutside(ref, () => {
        setIsShow(false);
    });

    return (
        <SelectContext.Provider value={contextValue}>
            <S.Wrapper className={className}>
                <S.StyledInput
                    type="text"
                    iconRight="angle-down"
                    emptyIconColor={theme.colors.darkGray}
                    notEmptyIconColor={theme.colors.darkGray}
                    placeholder={placeholder}
                    variant={variant}
                    inputSize={inputSize}
                    color={color}
                    value={value}
                    opacityIcons={false}
                    onClick={handlerClick}
                    onTouchStart={handlerClick}
                    onRightIconClick={handlerClick}
                    readOnly
                />
                <Transition nodeRef={ref} in={isShow} timeout={150} unmountOnExit>
                    {(state) => (
                        <S.Dropdown ref={ref} style={animation[state]}>
                            {children}
                        </S.Dropdown>
                    )}
                </Transition>
            </S.Wrapper>
        </SelectContext.Provider>
    );
});
