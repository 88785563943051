import { StyledVariants } from 'interfaces/StyledVariants';
import styled, { css } from 'styled-components';
import { laptopDownMedia } from 'styles/helpers';
import { TagVariants } from './types';

interface TagProps {
    variant?: TagVariants;
    active?: boolean;
}

const variants: StyledVariants<TagVariants> = {
    light: css<TagProps>`
        color: ${({ active, theme }) => (active ? theme.colors.bg : theme.colors.gray)};
    `,
    dark: css<TagProps>`
        color: ${({ active, theme }) => (active ? theme.colors.bg : theme.colors.text)};
    `,
};

export const Tag = styled.button<TagProps>`
    font-family: ${({ theme }) => theme.familyPrimary};
    font-size: ${({ theme }) => `${theme.fontSize.xs}px`};
    line-height: 1;
    font-weight: 500;
    background: ${({ active, theme }) => (active ? theme.colors.accent : theme.colors.bg)};
    border: 0;
    border-radius: 24px;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease-out;
    padding: 6px 12px;

    &:hover {
        background: ${({ theme }) => theme.colors.lightAccent};
        color: ${({ theme }) => theme.colors.bg};
    }

    ${laptopDownMedia(css`
        font-size: ${({ theme }) => `${theme.fontSize.xxs}px`};
    `)}

    ${({ variant = 'light' }) => variants[variant]};
`;
