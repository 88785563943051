import { memo, useCallback } from 'react';
import * as S from './styles';

interface LogoutProps {
    onConfirm: () => void;
}

export const Logout = memo((props: LogoutProps) => {
    const { onConfirm } = props;

    const handlerOpenPopup = useCallback(() => {
        onConfirm();
    }, [onConfirm]);

    return (
        <S.Logout>
            <S.Title>Хотите выйти из системы?</S.Title>
            <S.StyledButton iconLeft="logout" showDivider={false} onClick={handlerOpenPopup}>
                Выйти
            </S.StyledButton>
        </S.Logout>
    );
});
