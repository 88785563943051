import { configureStore } from '@reduxjs/toolkit';
import $api from 'api';
import { authReducer } from 'store/auth';
import { commonReducer } from 'store/common';
import { ThunkExtraArg } from './types/StateSchema';

const rootReducers = {
    common: commonReducer,
    auth: authReducer,
};

const extraArg: ThunkExtraArg = {
    api: $api,
};

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: extraArg,
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
