import React, { CSSProperties, memo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useMatchMedia, useScrolledWrapper } from 'utils/hooks';
import { laptopUp } from 'config';
import { Br } from 'styles/components';
import { Container } from 'components/Container';
import { Icon } from 'components/Icon';
import Tot from 'assets/images/tot.webp';
import TotRetina from 'assets/images/tot@2x.webp';
import Roll from 'assets/images/roll.webp';
import RollRetina from 'assets/images/roll@2x.webp';
import * as S from './styles';

// TODO: С этой лапшой надо что-то делать
export const AllInclusive = memo(() => {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement>(null);
    const laptopMatch = useMatchMedia(laptopUp);
    const position = useScrolledWrapper(ref);

    const animation = ({
        verticalRate,
        horizontalRate,
    }: {
        verticalRate?: number;
        horizontalRate?: number;
    }): CSSProperties => {
        if (!laptopMatch) {
            return {};
        }

        let transformX = '0';
        let transformY = '0';

        if (horizontalRate) {
            transformX = `${(1 - position) * horizontalRate * 500}px`;
        }

        if (verticalRate) {
            transformY = `${(1 - position) * verticalRate * 500}px`;
        }

        if (position > 0 && position <= 1) {
            return { transform: `translate(${transformX}, ${transformY})` };
        }

        return { transform: 'translate(0)' };
    };

    return (
        <S.AllInclusive id="all-inclusive" ref={ref}>
            <S.Sticky>
                <Container>
                    <S.Cards>
                        <S.Card
                            style={{
                                order: !laptopMatch ? 1 : undefined,
                                ...animation({ verticalRate: 0.8 }),
                            }}
                        >
                            <S.Head as="h3" style={{ lineHeight: 1.3 }}>
                                <S.Accent>бесплатный</S.Accent>
                                <Br ignore={!laptopMatch} /> доступ
                                <Br ignore={!laptopMatch} /> ко <S.Accent>всем</S.Accent>
                                <Br ignore={!laptopMatch} /> материалам
                                <Br ignore={!laptopMatch} /> курса
                            </S.Head>
                        </S.Card>
                        <S.Card
                            rowStart={1}
                            rowEnd={-1}
                            columnStart={2}
                            style={{
                                order: !laptopMatch ? 3 : undefined,
                                ...animation({ verticalRate: 0.4 }),
                            }}
                        >
                            <S.Head
                                as="h3"
                                style={{
                                    fontSize: '100px',
                                    lineHeight: 1,
                                    fontWeight: 600,
                                }}
                            >
                                <S.Accent>12</S.Accent>
                            </S.Head>
                            <S.Head as="div" style={{ marginTop: '-10px' }}>
                                <S.Accent>исторических</S.Accent>
                                <br />
                                персонажей
                            </S.Head>
                            <S.Descr
                                style={{
                                    marginTop: !laptopMatch ? '150px' : 'auto',
                                }}
                            >
                                проведут вас
                                <Br ignore={!laptopMatch} /> через
                                <Br ignore={laptopMatch} /> весь
                                <Br ignore={!laptopMatch} /> курс
                            </S.Descr>
                            <S.Image src={Tot} srcSet={`${TotRetina} 2x`} alt="" />
                        </S.Card>
                        {laptopMatch && (
                            <>
                                <S.Card style={animation({ horizontalRate: 0.2 })}>
                                    <S.Head as="h3" style={{ lineHeight: 1.5 }}>
                                        метод-пакет
                                        <br />
                                        для <S.Accent>учителей</S.Accent>
                                    </S.Head>
                                    <S.StickBottom>
                                        <S.Descr>
                                            получите рекомендации по использованию интерактивных материалов после
                                            регистрации в пилоте
                                        </S.Descr>
                                    </S.StickBottom>
                                </S.Card>
                                <S.Card style={animation({ horizontalRate: 0.3 })}>
                                    <S.Head as="h3" style={{ lineHeight: 1.5 }}>
                                        <S.Accent>расширенная</S.Accent>
                                        <br />
                                        программа
                                    </S.Head>
                                    <S.Descr>
                                        для 5 класса
                                        <br />в формате игры
                                    </S.Descr>
                                </S.Card>
                            </>
                        )}
                        <S.Card
                            style={{
                                order: !laptopMatch ? 2 : undefined,
                                ...animation({ verticalRate: 1 }),
                            }}
                        >
                            <S.Head
                                as="h3"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: '18px',
                                    fontSize: theme.fontSize.xxl,
                                    fontWeight: 600,
                                }}
                            >
                                <Icon glyph="like" size={45} />
                                <S.Accent
                                    style={{
                                        fontSize: !laptopMatch ? '56px' : undefined,
                                    }}
                                >
                                    95%
                                </S.Accent>
                            </S.Head>
                            <S.Descr>
                                школьников*
                                <Br ignore={!laptopMatch} /> поставили «лайк»
                                <br />
                                этому курсу
                            </S.Descr>
                            <S.StickBottom>
                                <S.Note>
                                    * По итогам пилота
                                    <Br ignore={!laptopMatch} /> в 27 школах
                                </S.Note>
                            </S.StickBottom>
                        </S.Card>
                        <S.Card
                            columnStart={3}
                            columnEnd={-1}
                            style={{
                                order: !laptopMatch ? 4 : undefined,
                                ...animation({ horizontalRate: 0.6 }),
                            }}
                        >
                            <S.Head
                                style={{
                                    fontSize: laptopMatch ? '42px' : '24px',
                                    lineHeight: 1,
                                }}
                            >
                                <S.Accent>
                                    достоверные
                                    <br />
                                    материалы
                                </S.Accent>
                            </S.Head>
                            <S.Descr>
                                в разработке курса принимали
                                <br />
                                участие специалисты по
                                <br />
                                Древнему Египту
                            </S.Descr>
                            {laptopMatch && <S.Image src={Roll} srcSet={`${RollRetina} 2x`} alt="" />}
                        </S.Card>
                    </S.Cards>
                </Container>
            </S.Sticky>
        </S.AllInclusive>
    );
});
