import { User } from 'components/User';
import React, { memo } from 'react';
import { Logo } from 'components/Logo';
import { useMatchMedia } from 'utils/hooks';
import { laptopUp } from 'config';
import * as S from './styles';

export interface HeaderProps {
    showBg?: boolean;
}

export const Header = memo((props: HeaderProps) => {
    const { showBg = false } = props;

    const laptopMatch = useMatchMedia(laptopUp);

    return (
        <S.Header>
            <S.HeaderContainer>
                <Logo variant={!laptopMatch || showBg ? 'dark' : 'light'} width={160} height={29} />
                <User variant={showBg ? 'dark' : 'light'} />
            </S.HeaderContainer>
            {laptopMatch && showBg && <S.BlurBg />}
        </S.Header>
    );
});
