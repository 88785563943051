import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { AuthResponse } from '../models/AuthResponse';

export const login = createAsyncThunk<AuthResponse, { email: string; password: string }, ThunkConfig<string>>(
    'auth/login',
    async (authData, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post<AuthResponse>('/auth/login', authData);

            if (!response.data) {
                throw new Error();
            }

            localStorage.setItem('token', response.data.accessToken);
            return response.data;
        } catch (error) {
            return rejectWithValue('error');
        }
    }
);
