import { Button, ButtonSizes } from 'components/Button';
import { InputColors, InputSizes, InputVariants } from 'components/Input';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthError, getAuthIsLoading, login } from 'store/auth';
import { validateEmail } from 'utils/helpers';
import { useAppDispatch } from 'utils/hooks';
import * as S from './styles';

export const LoginForm = memo(() => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isError, setIsError] = useState(false);
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getAuthIsLoading);
    const isServerError = useSelector(getAuthError);

    const handlerEmailChange = useCallback((value: string) => {
        setIsError(false);
        setEmail(value);
    }, []);

    const handlerPasswordVisibleClick = useCallback(() => {
        setPasswordVisible((passwordVisible) => !passwordVisible);
    }, []);

    const handlerLoginClick = useCallback(() => {
        if (email.length === 0 || validateEmail(email) || password.length === 0) {
            setIsError(true);
            return;
        }

        dispatch(
            login({
                email,
                password,
            })
        );
    }, [dispatch, email, password]);

    return (
        <S.Popup>
            <S.Title>Вход</S.Title>
            <S.StyledLabel>Введите e-mail</S.StyledLabel>
            <S.StyledInput
                type="text"
                value={email}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                required
                onChange={handlerEmailChange}
            />
            <S.StyledLabel>Введите пароль</S.StyledLabel>
            <S.StyledInput
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                iconRight={passwordVisible ? 'eye-open' : 'eye-off'}
                onRightIconClick={handlerPasswordVisibleClick}
                opacityIcons
                required
                onChange={(value) => setPassword(value)}
            />
            <Button
                size={ButtonSizes.l}
                iconRight={isLoading ? 'loader' : 'arrow-right'}
                iconColor="white"
                isIconRightAnimation={isLoading}
                disabled={!email || !password || isLoading}
                onClick={handlerLoginClick}
            >
                Войти
            </Button>
            {isError && <S.Error>Введите корректный email</S.Error>}
            {isServerError && <S.Error>Вы не зарегистрированы или ввели неверные логин и пароль.</S.Error>}
        </S.Popup>
    );
});
