import styled from 'styled-components';

interface ContainerProps {
    fullHeight?: boolean;
}

export const Container = styled.div<ContainerProps>`
    width: 100%;
    height: ${({ fullHeight }) => fullHeight && '100svh'};
    max-width: ${({ theme }) => `${theme.maxWidth}px`};
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
`;
