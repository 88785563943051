import styled, { css } from 'styled-components';
import { Text, Title } from 'styles/components';
import { laptopUpMedia } from 'styles/helpers';

export interface CardProps {
    rowStart?: number;
    rowEnd?: number;
    columnStart?: number;
    columnEnd?: number;
}

export const AllInclusive = styled.div`
    margin: 104px 0 80px;

    ${laptopUpMedia(css`
        height: 130vh;
        margin: 0;
    `)}
`;

export const Sticky = styled.div`
    ${laptopUpMedia(css`
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        height: 100vh;
        overflow: hidden;
    `)}
`;

export const Cards = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;

    ${laptopUpMedia(css`
        display: grid;
        grid-template: repeat(2, 1fr) / 1fr 1.5fr 1.1fr 1.1fr;
        gap: 24px;
    `)}
`;

export const Card = styled.section<CardProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    padding: 24px;

    ${laptopUpMedia(css<CardProps>`
        grid-row-start: ${({ rowStart }) => rowStart};
        grid-row-end: ${({ rowEnd }) => rowEnd};
        grid-column-start: ${({ columnStart }) => columnStart};
        grid-column-end: ${({ columnEnd }) => columnEnd};
        will-change: transform;
    `)}
`;

export const Head = styled(Title)`
    position: relative;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: 1.1;
    z-index: 20;

    ${laptopUpMedia(css`
        font-size: 28px;
    `)}
`;

export const Accent = styled.span`
    color: ${({ theme }) => theme.colors.accent};
`;

export const Descr = styled(Text)`
    position: relative;
    color: ${({ theme }) => theme.colors.darkGray};
    z-index: 20;

    ${laptopUpMedia(css`
        font-weight: 600;
    `)}
`;

export const StickBottom = styled.div`
    margin-top: auto;
`;

export const Note = styled(Text)`
    font-family: ${({ theme }) => theme.familySecondary};
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: 12px;
    font-weight: 600;
`;

export const Image = styled.img`
    position: absolute;
    bottom: 0;
    right: -50px;
    max-width: 100%;
    height: auto;
    border-radius: 24px;
    z-index: 10;

    ${laptopUpMedia(css`
        right: 0;
    `)}
`;
