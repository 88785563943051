import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser, getIsAuth } from 'store/auth';
import { checkModuleAccess } from 'utils/helpers';

export const useIsAccess = (roles?: string[]) => {
    const [isAccess, setIsAccess] = useState(false);
    const isAuth = useSelector(getIsAuth);
    const user = useSelector(getAuthUser);

    useEffect(() => {
        let access = false;

        if (!roles) {
            access = true;
        } else if (isAuth && user?.roles) {
            access = checkModuleAccess(roles, user.roles);
        }

        setIsAccess(access);
    }, [roles, isAuth, user]);

    return isAccess;
};
