import { InputColors } from 'components/Input';
import { ModuleCard } from 'components/ModuleCard';
import { Option } from 'components/Select';
import { ThemeCard } from 'components/ThemeCard';
import { laptopUp } from 'config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    commonActions,
    fetchProgress,
    getGroupsFilter,
    getModules,
    getSearchResult,
    getTypesFilter,
} from 'store/common';
import { useTheme } from 'styled-components';
import { useAppDispatch, useMatchMedia } from 'utils/hooks';
import * as S from './styles';

export const Content = () => {
    const [search, setSearch] = useState('');
    const [group, setGroup] = useState('');
    const [type, setType] = useState('');

    const modules = useSelector(getModules);
    const searchResult = useSelector(getSearchResult);
    const types = useSelector(getTypesFilter);
    const groups = useSelector(getGroupsFilter);
    const laptopMatch = useMatchMedia(laptopUp);
    const dispatch = useAppDispatch();

    const theme = useTheme();

    const handlerGroupSelect = (value: string) => {
        setGroup((group) => (group === value ? '' : value));
    };

    const handlerTypeSelect = (value: string) => {
        setType((type) => (type === value ? '' : value));
        setSearch('');
        dispatch(commonActions.resetSearchResult());
    };

    const handlerSearchChange = (value: string) => {
        setSearch(value);
    };

    const cleanSearch = () => {
        setSearch('');
    };

    useEffect(() => {
        dispatch(commonActions.getModules({ group, type }));
    }, [dispatch, group, type]);

    useEffect(() => {
        dispatch(commonActions.getFilterTypes());
    }, [dispatch, group]);

    useEffect(() => {
        dispatch(commonActions.getFilterGroups());
    }, [dispatch, type]);

    useEffect(() => {
        if (search.length > 3) {
            setGroup('');
            setType('');
            dispatch(commonActions.searchByThemes(search));
        } else {
            dispatch(commonActions.resetSearchResult());
        }
    }, [dispatch, search]);

    useEffect(() => {
        dispatch(fetchProgress());
    }, [dispatch]);

    return (
        <S.ContentContainer>
            <S.Head as="h2">Модули</S.Head>
            <S.Filter>
                <S.StyledSelect
                    placeholder="Тема"
                    value={group}
                    color={laptopMatch ? InputColors.white : InputColors.bg}
                    onSelect={handlerGroupSelect}
                >
                    <Option value="">Показать все</Option>
                    {groups.map((group) => (
                        <Option key={group} value={group}>
                            {group}
                        </Option>
                    ))}
                </S.StyledSelect>
                <S.StyledSelect
                    placeholder="Тип контента"
                    value={type}
                    color={laptopMatch ? InputColors.white : InputColors.bg}
                    onSelect={handlerTypeSelect}
                >
                    <Option value="">Показать все</Option>
                    {types.map((type) => (
                        <Option key={type} value={type}>
                            {type}
                        </Option>
                    ))}
                </S.StyledSelect>
                <S.StyledInput
                    type="text"
                    placeholder="Поиск"
                    value={search}
                    color={laptopMatch ? InputColors.white : InputColors.bg}
                    iconLeft="search"
                    iconRight={search.length > 3 ? 'close' : undefined}
                    emptyIconColor={theme.colors.darkGray}
                    notEmptyIconColor={theme.colors.darkGray}
                    onRightIconClick={cleanSearch}
                    onChange={handlerSearchChange}
                />
            </S.Filter>
            <S.Modules>
                {searchResult
                    ? // Если поиск и есть результаты поиска
                      searchResult.length > 0 &&
                      searchResult.map((theme) => (
                          <ThemeCard key={theme.id} data={theme} activeType={type} onTypeSelect={handlerTypeSelect} />
                      ))
                    : // Если не поиск
                      modules.map((module) => (
                          <ModuleCard
                              key={module.id}
                              data={module}
                              enabledFilter={!!group || !!type}
                              activeGroup={group}
                              onGroupSelect={handlerGroupSelect}
                          >
                              {(themes) => (
                                  <>
                                      {!laptopMatch && <S.StyledMobileDevicesComment />}
                                      {themes.map((theme) => (
                                          <S.StyledThemeCard
                                              key={theme.id}
                                              data={theme}
                                              activeType={type}
                                              onTypeSelect={handlerTypeSelect}
                                          />
                                      ))}
                                  </>
                              )}
                          </ModuleCard>
                      ))}
            </S.Modules>

            {
                // Если поиск и ничего не найдено
                searchResult && searchResult.length === 0 && (
                    <S.NotFound>
                        Ничего не нашлось.
                        <br />
                        Попробуйте другой запрос.
                    </S.NotFound>
                )
            }
        </S.ContentContainer>
    );
};
