import React, { ReactNode, useEffect } from 'react';
import { checkAuth } from 'store/auth';
import { useAppDispatch } from 'utils/hooks';

interface AuthProps {
    children: ReactNode;
}

export const CheckAuth = (props: AuthProps) => {
    const { children } = props;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(checkAuth());
        }
    }, [dispatch]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};
