import { LeadForm } from 'components/LeadForm';
import { Logo } from 'components/Logo';
import React, { memo } from 'react';
import * as S from './styles';

export const GetLead = memo(() => (
    <S.GetLeadContainer as="section" fullHeight>
        <Logo variant="dark" width={304} height={56} />
        <S.Head as="h2">
            Оставьте свою почту и получите
            <br />
            первыми доступ к материалам.
        </S.Head>
        <S.Descr>участие в пилоте бесплатно</S.Descr>
        <S.Wrapper>
            <LeadForm />
        </S.Wrapper>
    </S.GetLeadContainer>
));
