import { Container } from 'components/Container';
import { LeadForm } from 'components/LeadForm';
import React, { memo } from 'react';
import * as S from './styles';

interface MobileGetLeadProps {
    title?: string;
    descr?: string;
}

export const MobileGetLead = memo((props: MobileGetLeadProps) => {
    const { title, descr } = props;

    return (
        <Container>
            <S.MobileGetLead>
                {title && <S.Head as="h2">{title}</S.Head>}
                {descr && <S.Descr>{descr}</S.Descr>}
                <LeadForm />
            </S.MobileGetLead>
        </Container>
    );
});
