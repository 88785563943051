import { Button, ButtonVariants } from 'components/Button';
import { memo, useCallback } from 'react';
import { logout } from 'store/auth';
import { useAppDispatch } from 'utils/hooks';
import * as S from './styles';

interface ConfirmLogoutProps {
    onCancel: () => void;
}

export const ConfirmLogout = memo((props: ConfirmLogoutProps) => {
    const { onCancel } = props;

    const dispatch = useAppDispatch();

    const handlerLogoutClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <S.ConfirmLogout>
            <S.Title>Уже уходите?</S.Title>
            <S.Descr>
                Чтобы выйти из системы нажмите «Выйти», чтобы остаться залогиненым на сайте нужно нажать «Остаться».
            </S.Descr>
            <S.Buttons>
                <Button variant={ButtonVariants.outline} onClick={handlerLogoutClick}>
                    Выйти
                </Button>
                <Button onClick={onCancel}>Остаться</Button>
            </S.Buttons>
        </S.ConfirmLogout>
    );
});
