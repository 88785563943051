import React, { ReactNode, useContext } from 'react';
import { SelectContext } from './SelectContext';
import * as S from './styles';

interface OptionProps {
    value: string;
    children?: ReactNode;
}

export const Option: React.FC<OptionProps> = ({ value, children }) => {
    const { handlerSelect, currentValue } = useContext(SelectContext);

    const handlerClick = () => {
        handlerSelect?.(value);
    };

    return (
        <S.Option onClick={handlerClick} selected={currentValue === value}>
            {children}
        </S.Option>
    );
};
