import { CheckAuth } from 'components/CheckAuth';
import { laptopUp } from 'config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from 'store';
import { FontsStyles } from 'styles/fonts';
import { GlobalStyles } from 'styles/global';
import { baseTheme, mobileTheme } from 'styles/theme';
import { useMatchMedia } from 'utils/hooks';
import routes from './routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const App = () => {
    const laptopMatch = useMatchMedia(laptopUp);

    return (
        <Provider store={store}>
            <CheckAuth>
                <ThemeProvider theme={laptopMatch ? baseTheme : mobileTheme}>
                    <RouterProvider router={routes} />
                    <FontsStyles />
                    <GlobalStyles />
                </ThemeProvider>
            </CheckAuth>
        </Provider>
    );
};

root.render(<App />);
