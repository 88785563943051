import * as C from 'styles/components';
import * as S from './styles';

interface MobileDevicesCommentProps {
    className?: string;
}

export const MobileDevicesComment = (props: MobileDevicesCommentProps) => {
    const { className } = props;

    return (
        <S.MobileDevicesComment className={className}>
            <S.Head as="h3">зайдите с другого устройства</S.Head>
            <C.Text>
                <p>
                    Прохождение курса доступно только на устройствах с большей диагональю, например, компьютере или
                    ноутбуке.
                </p>
                <p>На мобильных устройствах доступен просмотр тем.</p>
            </C.Text>
        </S.MobileDevicesComment>
    );
};
