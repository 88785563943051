import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modules, fuse } from 'data';
import { filter, FilterName, filterValues, FilterValues } from 'utils/helpers';
import { CommonSchema } from '../types/CommonSchema';
import { fetchProgress } from '../services/fetchProgress';

const initialState: CommonSchema = {
    loading: false,
    error: undefined,
    data: [],
    groups: [],
    types: [],
    progress: undefined,
    searchResult: undefined,
};

export const commonSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        getModules: (state, action: PayloadAction<FilterValues>) => {
            state.data = filter(modules, action.payload);
        },
        searchByThemes: (state, action: PayloadAction<string>) => {
            const result = fuse.search(action.payload);
            state.searchResult = result.map((theme) => theme.item);
        },
        resetSearchResult: (state) => {
            state.searchResult = undefined;
        },
        getFilterTypes: (state) => {
            state.types = filterValues(state.data, FilterName.TYPES);
        },
        getFilterGroups: (state) => {
            state.groups = filterValues(state.data, FilterName.GROUPS);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProgress.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchProgress.fulfilled, (state, action) => {
                state.progress = action.payload;
                state.loading = false;
                state.error = '';
            })
            .addCase(fetchProgress.rejected, (state) => {
                state.loading = true;
                state.error = 'error';
            });
    },
});

export const { actions: commonActions } = commonSlice;
export const { reducer: commonReducer } = commonSlice;
