import { Container } from 'components/Container';
import { Text, Title } from 'styles/components';
import styled, { css, keyframes } from 'styled-components';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';

export const Promo = styled.section`
    position: relative;
    height: 100svh;
    color: ${({ theme }) => theme.colors.bg};

    ${laptopUpMedia(css`
        height: 200vh;
    `)}
`;

export const Sticky = styled.div`
    overflow: hidden;

    ${laptopUpMedia(css`
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        height: 100vh;
    `)}
`;

export const MobileShadow = styled.div`
    ${laptopDownMedia(css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 100%) no-repeat;
        z-index: 25;
    `)}
`;

export const PromoContainer = styled(Container)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 56px;
    padding-bottom: 80px;

    ${laptopUpMedia(css`
        display: grid;
        grid-template: 1fr repeat(2, min-content) 1fr / max-content 1fr;
        grid-template-areas:
            '.     video'
            'title video'
            'descr video'
            '.     video';
        padding-top: 0;
        padding-bottom: 0;
        z-index: 20;
    `)}
`;

export const Head = styled(Title)`
    position: relative;
    grid-area: title;
    font-size: ${({ theme }) => `${theme.fontSize.xxl}px`};
    line-height: 1.2;
    margin-bottom: 42px;
    z-index: 30;

    ${laptopUpMedia(css`
        margin-bottom: 48px;
    `)}
`;

export const PersonWrapper = styled.div`
    grid-area: video;
    height: 100%;
    box-sizing: border-box;
    padding-top: 80px;
    margin-right: -2%;

    ${laptopDownMedia(css`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
    `)}
`;

export const Person = styled.video`
    width: 150%;
    height: 100%;
    object-fit: contain;
    object-position: bottom center;
    margin-left: -20%;

    ${laptopUpMedia(css`
        width: 120%;
    `)}
`;

export const Descr = styled(Text)`
    position: relative;
    grid-area: descr;
    z-index: 30;
`;

export const StyledButton = styled(Button)`
    position: relative;
    z-index: 30;

    ${laptopUpMedia(css`
        margin-top: 48px;
    `)}
`;

export const VideoContainer = styled(Container)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin-inline: auto;
    transform: translateY(100%);
    z-index: 40;

    ${laptopDownMedia(css`
        display: none;
    `)}
`;

export const IconAnimation = keyframes`
  0% { transform: translate(-50%, 0); }
  50% { transform: translate(-50%, 20px); }
  100% { transform: translate(-50%, 0); }
`;

export const NextScreenIcon = styled(Icon)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 52px;
    animation: ${IconAnimation} 2s ease infinite;
    cursor: pointer;
    margin: auto;
    z-index: 30;

    ${laptopDownMedia(css`
        display: none;
    `)}
`;
