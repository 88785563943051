import styled, { css } from 'styled-components';
import PromoBg from 'assets/images/promo-bg.webp';

export const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('${PromoBg}') center center / cover no-repeat;
    background-color: ${({ theme }) => theme.colors.promoBg};
    overflow: hidden;
    z-index: 10;

    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 190%;
        height: 190%;
        background: radial-gradient(50% 50% at 50% 50%, #186dec 0%, rgba(24, 109, 236, 0) 100%);
        mix-blend-mode: color;
        opacity: 0.6;
        pointer-events: none;
        z-index: 10;
    }

    &::before {
        left: -100%;
        top: -114%;
    }

    &::after {
        left: -3%;
        top: 8%;
    }
`;

export const Br = styled.br<{ ignore?: boolean }>`
    ${({ ignore }) =>
        ignore &&
        css`
            display: none;
        `}
`;

export const ProgressBar = styled.progress`
    width: 100%;
    height: 4px;
    background: rgba(225, 227, 231, 0.5);
    border-radius: 100px;
    border: 0;

    &::-webkit-progress-value {
        background: ${({ theme }) => theme.colors.accent};
        border-radius: 100px 0 0 100px;
    }

    &::-webkit-progress-bar {
        background: rgba(225, 227, 231, 0.5);
        border-radius: 100px;
    }

    &::-moz-progress-bar {
        background: ${({ theme }) => theme.colors.accent};
        border-radius: 100px 0 0 100px;
    }
`;

export const Text = styled.div`
    p {
        margin: 20px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    b {
        font-weight: 700;
    }

    ul {
        padding-left: 20px;
        margin: 20px 0;

        &:first-child,
        &:last-child {
            margin: 0;
        }
    }
`;

export const Title = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-weight: 700;
    line-height: 1;
    margin: 0;
`;

export const Label = styled.div`
    font-family: ${({ theme }) => theme.familyPrimary};
    font-size: ${({ theme }) => theme.fontSize.l}px;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;
`;
