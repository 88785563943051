import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModulesProgress } from '../types/Progress';

export const fetchProgress = createAsyncThunk<ModulesProgress, undefined, { rejectValue: string }>(
    'progress/getProgress',
    async (_, { rejectWithValue }) => {
        try {
            const progress = localStorage.getItem('progress');
            return progress ? JSON.parse(progress) : {};
        } catch (error) {
            return rejectWithValue('error');
        }
    }
);
