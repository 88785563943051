import React, { forwardRef, memo, VideoHTMLAttributes } from 'react';
import PromoMp4 from 'assets/video/promo.mp4';
import PromoWebm from 'assets/video/promo.webm';
import PromoPoster from 'assets/images/promo-poster.webp';
import * as S from './styles';

type PromoVideoProps = Omit<
    VideoHTMLAttributes<HTMLVideoElement>,
    'controls' | 'preload' | 'width' | 'height' | 'poster'
>;

const PromoVideo = forwardRef<HTMLVideoElement, PromoVideoProps>((props, ref) => {
    const { autoPlay = false } = props;

    return (
        <S.Video ref={ref} poster={PromoPoster} autoPlay={autoPlay} controls preload="auto" width={1198} height={600}>
            <source src={PromoMp4} type="video/mp4" />
            <source src={PromoWebm} type="video/webm" />
        </S.Video>
    );
});

export const MemoPromoVideo = memo(PromoVideo);
