import { Container } from 'components/Container';
import { Text, Title } from 'styles/components';
import styled from 'styled-components';

export const GetLeadContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const Head = styled(Title)`
    font-size: ${({ theme }) => `${theme.fontSize.xxl}px`};
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.darkGray};
    margin-top: 80px;
`;

export const Descr = styled(Text)`
    font-family: ${({ theme }) => theme.familySecondary};
    font-size: ${({ theme }) => `${theme.fontSize.xl}px`};
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: 600;
    opacity: 0.4;
    margin: 16px 0 92px;
`;

export const Wrapper = styled.div`
    max-width: 400px;
    text-align: center;
`;
