export enum InputSizes {
    s = 's',
    m = 'm',
    l = 'l',
}

export enum InputVariants {
    solid = 'solid',
    outline = 'outline',
}

export enum InputColors {
    error = 'error',
    white = 'white',
    lightGray = 'lightGray',
    bg = 'bg',
}
