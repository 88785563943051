import { Input } from 'components/Input';
import styled, { css } from 'styled-components';
import check from 'assets/icons/check.svg';

interface OptionProps {
    selected: boolean;
}

export const Wrapper = styled.div`
    position: relative;
`;

export const StyledInput = styled(Input)`
    input {
        cursor: pointer;
    }
`;

export const Dropdown = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 330px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 24px 64px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    list-style: none;
    transition: opacity 0.15s ease-out;
    opacity: 0;
    padding: 20px 10px 20px 24px;
    margin: 16px 0 0;
    z-index: 9999;
`;

export const Option = styled.li<OptionProps>`
    position: relative;
    font-weight: 500;
    color: ${({ selected, theme }) => (selected ? theme.colors.lightGray : theme.colors.darkGray)};
    cursor: pointer;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    ${({ selected }) =>
        selected &&
        css`
            &::after {
                content: '';
                position: absolute;
                right: 5px;
                top: 2px;
                width: 20px;
                height: 20px;
                background-image: url('${check}');
                background-repeat: no-repeat;
                box-shadow: 0 7px 13px rgba(0, 109, 210, 0.3);
                border-radius: 12px;
            }
        `}
`;
