import { PromoVideo } from 'components/PromoVideo';
import React, { forwardRef, HTMLAttributes, memo, useRef } from 'react';
import { useClickOutside } from 'utils/hooks';
import * as S from './styles';

interface PromoVideoPopupProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    onOutsideClick?: () => void;
}

const PromoVideoPopup = forwardRef<HTMLDivElement, PromoVideoPopupProps>((props, ref) => {
    const { className, onOutsideClick, ...otherProps } = props;
    const videoRef = useRef<HTMLVideoElement>(null);

    useClickOutside(videoRef, () => {
        onOutsideClick?.();
    });

    return (
        <S.Wrapper ref={ref} className={className} {...otherProps}>
            <S.StyledContainer fullHeight>
                <PromoVideo ref={videoRef} />
            </S.StyledContainer>
        </S.Wrapper>
    );
});

export const MemoPromoVideoPopup = memo(PromoVideoPopup);
