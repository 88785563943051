import styled, { css } from 'styled-components';
import { Icon } from 'components/Icon';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';
import { Text, Title } from 'styles/components';

interface WrapperProps {
    isShowThemes?: boolean;
}

interface ModuleProps {
    inProgress?: boolean;
}

interface MoreIconProps {
    mirror: boolean;
}

interface ThemesWrapperProps {
    showed: boolean;
    height: number;
}

export const Module = styled.section<ModuleProps>`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.veryLightGray}`};
    padding: 24px;

    ${laptopDownMedia(css`
        margin-left: -24px;
        margin-right: -24px;
    `)}

    ${laptopUpMedia(css<ModuleProps>`
        display: grid;
        grid-template: ${({ inProgress }) => `repeat(${inProgress ? 7 : 6}, min-content) / max-content 1fr 20% 155px`};

        ${({ inProgress }) => {
            if (inProgress) {
                return css`
                    grid-template-areas:
                        'number   number   .    indev'
                        'head     head     .    resume'
                        'image    image    .    .'
                        'tags     tags     tags tags'
                        'descr    descr    .    .'
                        'progress progress .    .'
                        'more     .        .    .';
                `;
            }

            return css`
                grid-template-areas:
                    'number number .    indev'
                    'head   head   .    .'
                    'image  image  .    .'
                    'tags   tags   tags tags'
                    'descr  descr  .    .'
                    'more   .      .    .';
            `;
        }}

        padding: 48px;
    `)}
`;

export const Wrapper = styled.div<WrapperProps>`
    ${Module} {
        ${laptopDownMedia(css<WrapperProps>`
            border: ${({ isShowThemes }) => isShowThemes && 0};
        `)}
    }

    &:last-child ${Module} {
        border-bottom: ${({ isShowThemes }) => !isShowThemes && 0};
    }
`;

export const Number = styled.div`
    grid-area: number;
    display: flex;
    align-items: center;
    column-gap: 24px;
    font-family: ${({ theme }) => theme.familyPrimary};
    color: ${({ theme }) => theme.colors.lightGray};
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: -10px;

    ${laptopDownMedia(css`
        font-weight: 700;
        column-gap: 12px;
    `)}
`;

export const InDev = styled.div`
    grid-area: indev;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${laptopDownMedia(css`
        display: none;
    `)}
`;

export const InDevBadge = styled.div`
    display: flex;
    align-items: center;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.bg};
    color: ${({ theme }) => theme.colors.accent};
    font-weight: 500;
    padding: 8px 16px;
`;

export const Resume = styled.div`
    grid-area: resume;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${laptopDownMedia(css`
        display: none;
    `)}
`;

export const Head = styled(Title)`
    grid-area: head;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.accent};

    ${laptopUpMedia(css`
        font-size: 36px;
    `)}
`;

export const Image = styled.img`
    grid-area: image;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
`;

export const Tags = styled.div`
    grid-area: tags;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

export const Descr = styled(Text)`
    grid-area: descr;
`;

export const More = styled.button`
    grid-area: more;
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-family: ${({ theme }) => theme.familyPrimary};
    line-height: 1;
    font-size: ${({ theme }) => `${theme.fontSize.m}px`};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.lightGray};
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin-top: 12px;

    ${laptopDownMedia(css`
        font-size: ${({ theme }) => `${theme.fontSize.xs}px`};
    `)}
`;

export const MoreIcon = styled(Icon)<MoreIconProps>`
    will-change: transform;
    transform: ${({ mirror }) => mirror && 'rotate(180deg)'};
    transform-origin: center;
    transition: 0.3s ease-out;
`;

export const ProgressInfo = styled.div`
    grid-area: progress;
    display: flex;
    align-items: center;
    column-gap: 14px;

    ${laptopUpMedia(css`
        margin-top: 24px;
    `)}
`;

export const ProgressValue = styled.div`
    font-size: ${({ theme }) => `${theme.fontSize.xs}px`};
    color: ${({ theme }) => theme.colors.lightGray};

    ${laptopUpMedia(css`
        display: none;
    `)}
`;

export const ThemesWrapper = styled.div<ThemesWrapperProps>`
    height: ${({ showed, height }) => (showed ? height : 0)}px;
    visibility: ${({ showed }) => (showed ? 'visible' : 'hidden')};
    overflow: hidden;
    transition:
        height 0.3s ease-out,
        opacity 0.5s ease-out,
        visibility 0.5s ease-out;
    opacity: ${({ showed }) => (showed ? 1 : 0)};

    ${laptopDownMedia(css`
        padding-left: 24px;
        margin-left: -24px;
        margin-right: -24px;
    `)}

    ${laptopUpMedia(css`
        padding-left: 96px;
    `)}
`;
