import { Text } from 'styles/components';
import styled, { css } from 'styled-components';
import { laptopUpMedia } from 'styles/helpers';

export const Footer = styled.footer`
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    ${laptopUpMedia(css`
        background: ${({ theme }) => theme.colors.white};
    `)}
`;

export const TelegramLink = styled.a`
    display: inline-block;
    margin-bottom: 32px;
`;

export const Copyright = styled(Text)`
    font-family: ${({ theme }) => theme.familySecondary};
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 0.4;
    font-weight: 500;
`;
