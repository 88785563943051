import { Popup } from 'components/Popup';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
`;

export const UserName = styled.div<{ variant: 'dark' | 'light' }>`
    display: flex;
    align-items: center;
    column-gap: 24px;
    font-size: ${({ theme }) => theme.fontSize.s}px;
    color: ${({ variant, theme }) => (variant === 'light' ? '#FFFFFF' : theme.colors.dark)};
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
`;

export const Avatar = styled.img`
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
    background: ${({ theme }) => theme.colors.lightGray};
    box-shadow: 0 16px 20px ${rgba('#343A45', 0.1)};
`;

export const StyledPopup = styled(Popup)`
    position: absolute;
    top: calc(100% + 22px);
    right: 0;
`;
