import { LoginForm } from 'components/LoginForm';
import { Popup } from 'components/Popup';
import { memo, useCallback, useState } from 'react';
import { Button, ButtonSizes } from 'components/Button';

export const Login = memo(() => {
    const [isShow, setIsShow] = useState(false);

    const handlerOpenPopup = useCallback(() => {
        setIsShow(true);
    }, []);

    const handlerClosePopup = useCallback(() => {
        setIsShow(false);
    }, []);

    return (
        <>
            <Button size={ButtonSizes.s} onClick={handlerOpenPopup}>
                Войти
            </Button>
            <Popup isShow={isShow} onClose={handlerClosePopup}>
                <LoginForm />
            </Popup>
        </>
    );
});
