import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage, Home } from 'pages/';

const routes = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
    },
]);

export default routes;
